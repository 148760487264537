import http from '@/utils/request/http'

// 账号密码登录
export const login = (params = {}) => {
	return http.post('/api/login', params)
}

// 验证码登录
export const mobileLogin = (params = {}) => {
	return http.post('/api/mobileLogin', params)
}

// 手机号注册
export const registerUser = (params = {}) => {
	return http.post('/api/registerUser', params)
}
//忘记密码
export const changepassword = (params = {}) => {
	return http.post('/api/forgetPassword', params)
}
// 邮箱注册
export const emailRegister = (params = {}) => {
	return http.post('/api/emailRegister', params)
}

// 协议接口
export const agreement = (params = {}) => {
	return http.get('/api/agreement', params)
}