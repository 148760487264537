<template>
  <div class="mobile-register">
    <div class="mobile-register-view">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="mobile">
          <p class="mobile-register-view-title  title-top">{{ $t('mobile') }}</p>
          <div class="mobile-register-view-content mobile-register-view-mobile">
            <div class="codice-paese">
              <el-dropdown @command="changePhoneCode">
                <span class="el-dropdown-link">
                  {{ phoneCodeValue }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-for="item in phoneCodeList"
                      :key="item.id"
                      :command="item"
                  >
                    {{ item.country }}+{{ item.telephone_code }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <p class="line"></p>
            <el-input v-model="form.mobile" :placeholder="$t('placeholder')+$t('mobile')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <p class="mobile-register-view-title  title-top">{{ $t('vCodeText') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input v-model="form.verificationCode" :placeholder="$t('placeholder')+$t('vCodeText')"></el-input>
            <el-button type="primary" @click="changeCode" :disabled="vCodeLoading">{{ vCodeBtnText }}</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <p class="mobile-register-view-title title-top">{{ $t('password') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input type="password" v-model="form.password"
                      :placeholder="$t('placeholder')+$t('password')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="verifyPassword">
          <p class="mobile-register-view-title title-top">{{ $t('confirm') + $t('password') }}</p>
          <div class="mobile-register-view-content mobile-register-view-vcode">
            <el-input type="password" v-model="form.verifyPassword"
                      :placeholder="$t('placeholder') + $t('confirm') + $t('password')"></el-input>
          </div>
        </el-form-item>
        <el-button class="submit-login" type="primary" @click="submitForm">{{ $t('resetpassword') }}</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {checkMobile, forgetPassword, getTelephoneCode} from "@/api/Common";
import {registerUser} from "@/api/User";
import {sendVCode} from "@/utils/common";

export default {
  props: {},
  data() {
    return {
      mobile: '',
      vcode: '',
      treatyChecked: false,
      form: {
        mobile: '',
        password: '',
        verifyPassword: '',
        verificationCode: '',
        invite_code: '',
        email: ''
      },
      rules: {
        mobile: [
          {required: true, message: this.$t('placeholder') + this.$t('mobile'), trigger: 'blur'}
        ],
        password: [
          {required: true, message: this.$t('placeholder') + this.$t('password'), trigger: 'blur'}
        ],
        verifyPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error(this.$t('placeholder') + this.$t('confirm') + this.$t('password')))
              } else if (value !== this.form.password) {
                callback(new Error(this.$t('thePasswordEnteredTwiceDoesNotMatch')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        verificationCode: [
          {required: true, message: this.$t('placeholder') + this.$t('vCodeText'), trigger: 'blur'}
        ],
      },
      loading: false,
      phoneCodeList: '',
      phoneCodeValue: '中国+86',
      telephoneCode: '',
      vCodeLoading: false,
      vCodeCount: 60,
      vCodeBtnText: this.$t('testGetCode'),
      agreeType: 1,
    }
  },
  components: {},
  computed: {},
  created() {

  },
  mounted() {
    this.initTelephoneCode()
  },
  watch: {},
  methods: {
    sendVCode,
    initTelephoneCode() {
      getTelephoneCode().then((res) => {
        console.log('获取国地区手机编码成功:', res)
        this.phoneCodeList = res.data.list
      }).catch(err => {
        console.log('获取国地区手机编码失败:', err)
      })
    },
    changePhoneCode(command) {
      this.phoneCodeValue = `${command.country}+${command.telephone_code}`
      this.telephoneCode = command.telephone_code
    },
    changeCode() {
      if (this.form.mobile.trim()) {
        this.sendVCode(this.telephoneCode, this.form.mobile)
        this.countDown()
      } else {
        this.form.mobile = ''
        this.$message.error(this.$t('placeholder') + this.$t('mobile'))
      }
    },
    countDown() {
      let inter = setInterval(() => {
        if (this.vCodeCount == 0) {
          clearInterval(inter)
          this.vCodeLoading = false
          this.vCodeCount = 60
          this.vCodeBtnText = this.$t('testGetCode')
        } else {
          this.vCodeLoading = true
          this.vCodeCount--
          this.vCodeBtnText = `${this.vCodeCount}${this.$t('tryAgainInSeconds')}`
        }
      }, 1000)
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          checkMobile({
            mobile: this.form.mobile,
            code: this.form.verificationCode
          }).then((res) => {
            console.log('验证手机号成功:', res)
            forgetPassword({
              checkStr: res.data.checkStr,
              new_password: this.form.password,
              new_password_confirmed: this.form.password,
            }).then((res1) => {
              console.log('修改密码成功:', res1)
              this.$message.success(res1.msg)
              this.$router.push({
                path: '/login/vcodelogin'
              })
            }).catch((err1) => {
              console.log('修改密码失败:', err1)
            })
          }).catch((err) => {
            console.log('验证手机号失败:', err)
          })
          // if (this.treatyChecked) {
          //   registerUser(this.form).then(res => {
          //     console.log('注册成功:', res)
          //     this.goLogin()
          //   }).catch(err => {
          //     console.log('注册失败:', err)
          //   })
          // } else {
          //   this.$message.warning(this.$t('agreeToTheAgreement'))
          // }
          // this.loading = true
          // console.log(this.treatyChecked)
        } else {
          return false;
        }
      });
    },
    goLogin() {
      this.$router.push({
        path: '/login/vcodelogin'
      })
    }
  }
}
</script>

<style scoped lang='less'>
.mobile-register {
  margin-top: 23px;
  padding: 60px;
  background-color: @recommendMusicianListBGColor;
  border-radius: 6px;

  &-view {
    width: 300px;
    margin: 0 auto;

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    &-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
    }

    .title-top {
      margin-top: 40px;
    }

    &-content {
      width: 300px;
      height: 42px;
      margin-top: 10px;
      background-color: @loginInputBgColor;
      border-radius: 4px;

      .codice-paese {
        line-height: 42px;
        padding-left: 12px;
        width: 22%;

        ::v-deep .el-dropdown {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: @buttonTextColor;
        }
      }

      .line {
        width: 1px;
        height: 22px;
        background: @buttonTextColor;
        margin: 10px;
      }

      ::v-deep .el-input__inner {
        border: none;
        line-height: 42;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @buttonTextColor;
        padding: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &-mobile {
      display: flex;
    }

    &-vcode {
      display: flex;
      justify-content: space-between;

      ::v-deep .el-input__inner {
        padding-left: 12px;
      }
    }

    .submit-login {
      width: 100%;
      margin-top: 40px;
    }

    .agreement {
      margin-top: 12px;

      .and {
        color: @buttonTextColor;
      }
    }

    .login {
      margin-top: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
      text-align: center;
    }

    ::v-deep .el-button--primary {
      background-color: #3370FF !important;
      border-color: #3370FF !important;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
